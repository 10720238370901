.year-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.year-filter label {
    margin-left: 10px;
}
.floating-filter {
    display: flex;
    flex-direction: row-reverse;
}

.filter-option {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-left: 10px;
}
