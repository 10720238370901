.table-container-div {
  background-color: white;
  padding: 3px 10px 10px 10px;
  border-radius: 15px 15px 0 0;
  min-height: 100%;
  max-height: 100vh;
  overflow: hidden;

  .top-part {
    display: flex;
    width: 100%;

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: center;
    }
  }
}
