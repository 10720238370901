.not-in-plan {
  background-color: rgba(255, 0, 0, 0.25);
}

.in-plan {
  background-color: rgba(0, 255, 0, 0.25);
}

.more-options {
  cursor: pointer;
  z-index: 100;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 2px;

  &:hover {
    background-color: #f1f1f1;
  }
}