
.break_row {


    border-top: solid 5px black;

}

.break_row_right {

    border-right: solid 1px black;
    border-top: solid 5px black;

}
.dsg-input-align-right {
    text-align: center;
}

.break_row_left {

    border-left: solid 1px black;
    border-top: solid 5px black;

}
.left_border {
    

    border-left: solid 1px black;

}
.right_border {
  
    border-right: solid 1px black;

}

.row-deleted .dsg-cell {
    /* Red */
    background: #fff1f0;
}

.row-created .dsg-cell {
    /* Green */
    background: #f6ffed;
}
.row-active .dsg-cell {
    /* Orange */
    background: #f4ffa6;
}
.row-all_dvs_printed .dsg-cell {
    /* Orange */
    background: #6eff74;


}

.row-locked .dsg-cell {
    /* Orange */
    background: #ffb585;
}
.row-readonly .dsg-cell {
    /* Orange */
    background: #9affdd;
}

.row-mname .dsg-cell {
    /* Orange */
    background: #d5d5d5;
}
.row-dvs .dsg-cell {
    /* Orange */
    background: #95b6ff;
}
.row-updated .dsg-cell {
    /* Orange */
    background: #fda5a7;
  }
