.calendar-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-gap: 1px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  border-radius: 16px;
  border: 3px solid #dfdfdf;
  :host::ng-deep ngb-popover-window::ng-deep .arrow{
    display: none !important;
 }
  .calendar-header {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    // background-color: blue;
    .year {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      background-color: #a39fad;
      min-height: 1.8rem;
      color: white;
      font-size: 17px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      gap: 14px;
      .prev {
        cursor: pointer;
      }
      .next {
        cursor: pointer;
      }
    }
    .month {
      display: flex;
      justify-content: space-between;
      margin: auto;
      // background-color: #9e91bb;
      min-height: 1.8rem;
      max-width: 180px;
      color: black;
      font-size: 17px;
      font-weight: 600;
      .prev {
        cursor: pointer;
      }
      .next {
        cursor: pointer;
      }
    }
    .weekdays {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: 1fr;
      grid-gap: 1px;
      font-size: 16px;
      font-weight: bold;
      background-color: #a39fad;
      min-height: 2.6rem;
      .weekday {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #a880ff;
      }
    }
  }
  .calendar-body {
    .confirm {
      margin: auto;
      font-size:16px;
      display: flex;
      color: white;
      align-items: center;
      justify-content: center;
      height: 40px;

      span {
        cursor: pointer;
        height: 30px;
        width: fit-content;
        min-width: 150px;
        padding: 0 20px;
        text-align: center;
        border: 2px solid #ecebeb;
        border-radius: 16px;
        transition: .15s cubic-bezier(0.16, 1, 0.3, 1) all;
        background-color: #b99aff;
        margin: 15px;
      }

    }
    grid-column: 1 / -1;
    grid-row: 2 / -1;
    background-color: #a39fad;
    .days {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: repeat(5, 1fr);
      background-color: #f1eaff;
      .day {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f1eaff;
      }
    }
  }
  .calendar-item-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1px;
    background-color: #fefeff;
    padding-top: 10px;
    border: 0.1px solid #dfdfdf;

    .calendar-item-header {
      display: flex;
      justify-content: center;
      font-size: 18px;
      color: black;
      font-weight: 700;
    }
    .calendar-item-wrapper {
      color: #621aff;
    }
    .stacked-items {
      height: 80px;
      display: flex;
      flex-direction: column;
      border: solid transparent 1px;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .stacked-items-card{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: fit-content;
      max-width: 100%;
      padding: 2px;
      height: fit-content;
      margin: 2px 0px;
      border-radius: 5px;
      background-color: white;
      border: #a39fad solid 3px;
      cursor: pointer;

      &:hover{
        font-weight: 600;
      }
    }
    .checked {
      background-color: rgba(96, 227, 96, 0.492);
    }
  }
}


.calendar-item-popover-content{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1px;
  // background-color: red;
  border-radius: 1rem;
  .calendar-item-popover-header{
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: black;
    font-weight: 700;
  }
  .calendar-item-popover-body{
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 18px;
    color: black;
    font-weight: 700;
    .calendar-item-popover-content-item{
      background-color: #f1eaff;
      border-radius: 16px;
      padding: 12px;
      margin:  12px 4px;
      border: dashed 3px #a39fad;
      cursor: pointer;
      > * {
        color: black;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      }
    }
  }
}

.tooltip {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  z-index: 1000;
  margin-bottom: 100px;
  padding: 5px;
  border: 1px solid #a39fad;
  border-radius: 15px;

  .tooltip-body {
      display: flex;
      flex-direction: row;
      align-items: center;

      .tooltip-display-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        width: fit-content;
        padding: 0 5px;
        margin-right: 5px;

        &:hover {
          background-color: rgb(219, 219, 219);
        }

        &:active {
          background-color: rgb(192, 192, 192);
        }
      }
  }
}

.more-button {
  cursor: pointer;

  &:hover {
    font-weight: 600;
  }
}
