.edit-icon {
  cursor: pointer;
  border-radius: 50%;
  padding: 5px;
  transition: background-color 0.3s ease-in-out;
  font-size: 1.8rem;
  margin: 0 5px;
  margin-bottom: 5px;

  &:hover {
    background-color: #ccc;
  }
}