.menu-customer-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-top: white 1px solid;
  border-bottom: white 1px solid;

  &:hover {
    background-color: rgba(235, 235, 235, 0.5) !important;
  }
}